
import Vue from "vue"
import { Novel } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    onSelectBoxClick() {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    title() {
      if (!this.selectedItem) return "未選択";
      return this.selectedItem.title;
    },
    selectableNovels() {
      // 未選択状態にする作品を始めに挿入する
      const none: Novel = {
        novelId: "",
        title: "未選択",
        image: "",
        createdAt: "",
        updatedAt: ""
      };

      return [none].concat(this.novels);
    }
  },
  props: {
    novels: {
      type: Array,
      default: (() => [])
    },
    selectedItem: Object,
    onSelected: Function
  }
});

interface Data {
  isOpen: Boolean;
}

interface Computed {
  title: string;
  selectableNovels: Novel[];
}

interface Methods {
  onSelectBoxClick: () => void;
}

interface Props {
  novels: Novel[];
  selectedItem: Novel | undefined;
  onSelected: (novel: Novel) => void;
}
