
import Vue from "vue";

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    iconSize: {
      type: Number,
      default: 24,
    },
    fill: {
      type: String,
      default: "#474a4d",
    },
    onClick: Function,
  },
});

interface Props {
  iconSize: number;
  fill: string;
  onClick: () => void;
}
