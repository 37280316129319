
import Vue from "vue";
import { format } from "date-fns";
import { Memo, MemoTag } from "@/lib/models";
import Chip from "@/components/atoms/chips/Chip.vue";

// 行内に表示する最大メモタグの合計文字数
const maxMemoTagWordLength = 12;
// 行内に表示する最大メモタグの個数
const maxMemoTagCount = 4;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Chip },
  data() {
    return {
      overCount: 0,
    };
  },
  methods: {
    async onClickMemo() {
      const { $route, $router, $store, novelId, memo, onClickItem } = this;

      // メモIDをindexedDBに格納
      $store.dispatch("memoModule/openMemo", memo.id);

      if (onClickItem) {
        (onClickItem as any)(memo.id);
        return;
      }

      if ($route.params.memoId === memo.id) return;
      if (Object.prototype.hasOwnProperty.call($route.params, "novelId")) {
        $router.push({
          name: $route.name as string,
          params: { novelId, memoId: memo.id },
        });
        return;
      }
      if (Object.prototype.hasOwnProperty.call($route.query, "novelId")) {
        $router.push({
          name: $route.name as string,
          params: { memoId: memo.id },
          query: { novelId },
        });
        return;
      }

      $router.push({ name: $route.name as string, params: { memoId: memo.id } });
    },
    getSlicedMemoTags() {
      const tmpMemoTags: MemoTag[] = this.$store.getters["memoModule/memoTagsFromMemo"](this.memo);
      const memoTags: MemoTag[] = [];
      let totalLength = 0;
      for (let index = 0; index < tmpMemoTags.length; index += 1) {
        const memoTag: MemoTag = tmpMemoTags[index];
        let addLength = 0;

        if (memoTag.name.length > 4) addLength = 5;
        else addLength = memoTag.name.length;

        totalLength += addLength;

        if (totalLength < maxMemoTagWordLength && memoTags.length < maxMemoTagCount) {
          memoTags.push(memoTag);
        } else {
          totalLength -= addLength;
        }
      }

      this.overCount = tmpMemoTags.length - memoTags.length;

      return memoTags;
    },
  },
  computed: {
    title() {
      const { title } = this.memo;
      if (title !== undefined && title !== null && title !== "") return title;

      return "NO TITLE";
    },
    isActive() {
      if (this.$route.params.memoId === this.memo.id) {
        return true;
      }

      return false;
    },
    updatedAt() {
      const date = new Date(this.memo.updatedAt);
      return format(date, "YYYY/MM/DD HH:mm");
    },
  },
  props: {
    novelId: String,
    memo: Object,
    onClickItem: Function,
  },
});

interface Data {
  overCount: number;
}

interface Methods {
  onClickMemo: () => void;
  getSlicedMemoTags: () => MemoTag[];
}

interface Computed {
  title: string;
  isActive: boolean;
  updatedAt: string;
}

interface Props {
  novelId: string;
  memo: Memo;
  onClickItem?: void;
}
