
import { MemoTag } from "@/lib/models";
import TagCheckList from "@/components/molecules/TagCheckList.vue";
import Vue from "vue";

const ClickOutside = require("vue-click-outside");

export default Vue.extend<{}, {}, {}, Props>({
  components: { TagCheckList },
  directives: { ClickOutside },
  props: {
    filterTags: Array,
    onSelect: Function,
    onClose: Function,
  },
  computed: {
    memoTags() {
      return this.$store.state.memoModule.memoTags;
    },
    selectedTags() {
      return this.filterTags.map((filterTag) => filterTag.id);
    },
  },
});

interface Props {
  filterTags: MemoTag[];
  onSelect: (memoTag: MemoTag) => void;
  onClose: () => void;
}
