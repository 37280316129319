export {};

declare global {
  interface String {
    addEllipsis: (size?: number) => string;
  }
}

String.prototype.addEllipsis = function addEllipsis(size?: number) {
  if (!size) return this.toString();
  if (this.length <= size) return this.toString();
  return `${this.slice(0, size)}...`;
};
