
import Vue from "vue";
import { MemoTag } from "@/lib/models";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";

export default Vue.extend<{}, Methods, {}, Props>({
  components: { CheckboxIcon },
  methods: {
    isChecked(memoTag: MemoTag) {
      if (this.selectedMemoTags) {
        return this.selectedMemoTags.some((selectedMemoTag) => selectedMemoTag === memoTag.id);
      }

      return false;
    },
  },
  props: {
    memoTags: {
      type: Array,
      default: () => [],
    },
    selectedMemoTags: {
      type: Array,
      default: () => [],
    },
    onClickItem: Function,
  },
});

interface Methods {
  isChecked: (memoTag: MemoTag) => boolean;
}

interface Props {
  memoTags: MemoTag[];
  selectedMemoTags: string[];
  onClickItem: (memoTag: MemoTag) => void;
}
