
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { Novel, Memo, MemoTag } from "@/lib/models";
import SelectBox from "@/components/atoms/SelectBox.vue";
import RoundInput from "@/components/atoms/RoundInput.vue";
import TagCheckList from "@/components/molecules/TagCheckList.vue";
import DeleteableChip from "@/components/atoms/chips/DeleteableChip.vue";
import TagIcon from "@/components/atoms/TagIcon.vue";
import CloseIcon from "@/components/atoms/CloseIcon.vue";
import { Dialog } from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SelectBox, RoundInput, TagCheckList, DeleteableChip, TagIcon, CloseIcon },
  data() {
    return {
      createMemoTagName: "",
      isOpen: false,
    };
  },
  methods: {
    onOpenTagClick() {
      this.isOpen = !this.isOpen;
    },
    onNovelSelected(novel: Novel) {
      // eslint-disable-next-line vue/no-mutating-props
      this.memo.novelId = novel.novelId;
      this.onChange();
    },
    onChangeText(e: InputEvent) {
      e.preventDefault();
      const { target } = e;
      // targetの型を確定するとvalueを参照できる
      if (target instanceof HTMLInputElement) {
        this.createMemoTagName = target.value;
      }
    },
    onCreateTagButtonClick(e: MouseEvent) {
      e.preventDefault();
      this.$store.dispatch("memoModule/createMemoTag", {
        id: uuidv4(),
        name: this.createMemoTagName,
        callback: () => {
          this.createMemoTagName = "";
        },
      });
    },
    onUpdateTagChekbox(value: MemoTag) {
      let index: number = 0;
      let { items } = this.memo;
      if (!items) items = [];
      const hasTag = items.some((item, i) => {
        const result = item === value.id;
        if (result) index = i;

        return result;
      });

      if (hasTag) {
        items.splice(index, 1);
      } else {
        if (this.selectedTags.length >= 20) {
          this.showDialog(
            "追加できません。",
            "ひとつのメモに対して設定できるタグは20個までです。<br>他のタグを外して該当のタグを設定しましょう。"
          );
          return;
        }

        items.push(value.id);
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.memo.items = items;
      this.onChange();
    },
    onUnselectTag(value: any) {
      const { items } = this.memo;
      const index = items.findIndex((item) => item === value.id);
      items.splice(index, 1);
      // eslint-disable-next-line vue/no-mutating-props
      this.memo.items = items;
      this.onChange();
    },
    closeMenu() {
      this.isOpen = false;
    },
    showDialog(title: string, content: string) {
      const notifyDialog = new Dialog(DialogVue);
      const options = {
        title,
        content,
      };
      notifyDialog.show(options);
    },
  },
  computed: {
    relatedNovel() {
      return this.novels.find((element) => element.novelId === this.memo.novelId);
    },
    selectedTags() {
      if (this.memo.items) {
        const memoTags = this.memo.items.map((item) => this.memoTags.find((memoTag) => memoTag.id === item));
        return memoTags.filter((memoTag): memoTag is MemoTag => typeof memoTag !== "undefined");
      }

      return [];
    },
    novels() {
      return this.$store.state.novelModule.novels;
    },
    memoTags() {
      return this.$store.state.memoModule.memoTags;
    },
  },
  props: {
    memo: Object,
    onChange: Function,
    novelSelectable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    $route: "closeMenu",
  },
});

interface Data {
  createMemoTagName: string;
  isOpen: Boolean;
}

interface Methods {
  onOpenTagClick: () => void;
  onNovelSelected: (novel: Novel) => void;
  onChangeText: (e: InputEvent) => void;
  onCreateTagButtonClick: (e: MouseEvent) => void;
  onUpdateTagChekbox: (memoTag: MemoTag) => void;
  onUnselectTag: (memoTag: MemoTag) => void;
  closeMenu: () => void;
  showDialog: (title: string, content: string) => void;
}

interface Computed {
  relatedNovel: Novel | undefined;
  selectedTags: MemoTag[];
  novels: Novel[];
  memoTags: MemoTag[];
}

interface Props {
  memo: Memo;
  novelSelectable: boolean;
  onChange: () => void;
}
