
import Vue from "vue";
import * as lodash from "lodash";
import { v4 as uuidv4 } from "uuid";
import Chip from "@/components/atoms/chips/Chip.vue";
import MemoRow from "@/components/molecules/MemoRow.vue";
import PopupMenu from "@/components/molecules/PopupMenu.vue";
import MemoTagFilter from "@/components/organisms/MemoTagFilter.vue";
import SortIcon from "@/components/atoms/SortIcon.vue";
import AddCircleIcon from "@/components/atoms/AddCircleIcon.vue";
import RemoveCircleIcon from "@/components/atoms/RemoveCircleIcon.vue";
import DocumentOutlineIcon from "@/components/atoms/DocumentOutlineIcon.vue";
import { Memo, MemoTag, Novel } from "@/lib/models";
import { MemoStorage } from "@/lib/storages";
import { isAfter, isBefore } from "date-fns";

const ClickOutside = require("vue-click-outside");

// ソートの種類
const SortType = {
  createdAsc: "作成日(昇順)",
  createdDesc: "作成日(降順)",
  updatedAsc: "更新日(昇順)",
  updatedDesc: "更新日(降順)",
  titleAsc: "タイトル(昇順)",
  titleDesc: "タイトル(降順)",
} as const;

// enumは使わない方がいいみたいだからunion型にしてみた
// eslint-disable-next-line no-redeclare
type SortType = typeof SortType[keyof typeof SortType];

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: {
    Chip,
    MemoRow,
    PopupMenu,
    MemoTagFilter,
    SortIcon,
    AddCircleIcon,
    RemoveCircleIcon,
    DocumentOutlineIcon,
  },
  data() {
    return {
      showSortPopupMenu: false,
      showFilterMenu: false,
      filterTags: [],
      currentSortType: SortType.createdAsc,
      styleMemoTagFilter: { marginLeft: "0px" },
    };
  },
  computed: {
    memos() {
      const memos: Memo[] = lodash.cloneDeep(
        this.$store.getters["memoModule/memosFromMemoTagsAndNovel"](this.novelId, this.filterTags)
      );
      return this.sortMemos(memos);
    },
    novelTitle() {
      const novel: Novel = this.$store.getters["novelModule/novel"](this.novelId);
      if (novel) {
        return `${novel.title}の`;
      }

      return "すべての";
    },
  },
  methods: {
    switchFragment() {
      this.showSortPopupMenu = !this.showSortPopupMenu;
    },
    closePopupMenu() {
      this.showSortPopupMenu = false;
    },
    closeFilterMenu() {
      this.showFilterMenu = false;
    },
    onFilterClick() {
      this.showFilterMenu = !this.showFilterMenu;
    },
    async onCreateMemoButtonClick() {
      const id = uuidv4();
      const storage = new MemoStorage();
      const s3BucketPath = await storage.uploadFile(id, "");
      this.$store.dispatch("memoModule/createMemo", {
        id,
        novelId: this.novelId,
        s3BucketPath,
        // HACK: 関数切り出してもいいかも
        callback: () => {
          this.$store.dispatch("memoModule/openMemo", id);

          if (Object.prototype.hasOwnProperty.call(this.$route.params, "novelId")) {
            this.$router.push({
              name: this.$route.name as string,
              params: { novelId: this.novelId, memoId: id },
            });
            return;
          }
          if (Object.prototype.hasOwnProperty.call(this.$route.query, "novelId")) {
            this.$router.push({
              name: this.$route.name as string,
              params: { memoId: id },
              query: { novelId: this.novelId },
            });
            return;
          }

          this.$router.push({ name: this.$route.name as string, params: { memoId: id } });
        },
      });
    },
    onSelectedItem(item: SortType) {
      this.currentSortType = item;
      this.closePopupMenu();
    },
    onSelectedFilter(memoTag: MemoTag) {
      let index: number = 0;
      const hasTag = this.filterTags.some((filterTag, i) => {
        const result = filterTag.id === memoTag.id;
        if (result) index = i;

        return result;
      });
      if (hasTag) {
        this.filterTags.splice(index, 1);
      } else {
        this.filterTags.push(memoTag);
      }
    },
    sortMemos(memos: Memo[]) {
      return memos.sort((a, b) => {
        const aCreatedDate = new Date(a.createdAt);
        const bCreatedDate = new Date(b.createdAt);
        const aUpdatedDate = new Date(a.updatedAt);
        const bUpdatedDate = new Date(b.updatedAt);

        switch (this.currentSortType) {
          case SortType.createdAsc:
            return isBefore(aCreatedDate, bCreatedDate) ? -1 : 1;
          case SortType.createdDesc:
            return isAfter(aCreatedDate, bCreatedDate) ? -1 : 1;
          case SortType.updatedAsc:
            return isBefore(aUpdatedDate, bUpdatedDate) ? -1 : 1;
          case SortType.updatedDesc:
            return isAfter(aUpdatedDate, bUpdatedDate) ? -1 : 1;
          case SortType.titleAsc:
            if (a.title && b.title) {
              return a.title.localeCompare(b.title);
            }
            if (a.title) {
              return a.title.localeCompare("");
            }
            if (b.title) {
              return "".localeCompare(b.title);
            }
            return 0;
          case SortType.titleDesc:
            if (a.title && b.title) {
              return b.title.localeCompare(a.title);
            }
            if (b.title) {
              return b.title.localeCompare("");
            }
            if (a.title) {
              return "".localeCompare(a.title);
            }
            return 0;
          default:
            return isAfter(aUpdatedDate, bUpdatedDate) ? -1 : 1;
        }
      });
    },
  },
  props: {
    sortTypes: {
      type: Array,
      default: () => Object.values(SortType),
    },
    novelId: {
      type: String,
      default: "all",
    },
    memoId: String,
    onClickItem: Function,
  },
  watch: {
    async showFilterMenu(showFilterMenu: boolean) {
      if (showFilterMenu) {
        const { $refs, $nextTick } = this;

        await $nextTick();

        const containerElement = $refs.container as Element;

        if (containerElement) {
          const rect = containerElement.getBoundingClientRect();
          this.styleMemoTagFilter.marginLeft = `${rect.width}px`;
        }
      }
    },
  },
});

interface Data {
  showSortPopupMenu: boolean;
  showFilterMenu: boolean;
  filterTags: MemoTag[];
  currentSortType: SortType;
  styleMemoTagFilter: StyleMemoTagFilter;
}

interface Computed {
  memos: Memo[];
  novelTitle: string;
}

interface Methods {
  switchFragment: () => void;
  closePopupMenu: () => void;
  closeFilterMenu: () => void;
  onFilterClick: () => void;
  onCreateMemoButtonClick: () => void;
  onSelectedItem: (item: SortType) => void;
  onSelectedFilter: (memoTag: MemoTag) => void;
  sortMemos: (novels: Memo[]) => Memo[];
}

interface Props {
  sortTypes: string[];
  novelId: string;
  memoId: string;
  onClickItem?: void;
}

interface StyleMemoTagFilter {
  marginLeft: string;
}
